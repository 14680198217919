body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-outline-champion {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #f8de3e;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f8de3e;
  --bs-btn-hover-border-color: #f8de3e;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f8de3e;
  --bs-btn-active-border-color: #f8de3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8de3e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8de3e;
  --bs-gradient: none;
}

.nav-links-champion {
  --bs-nav-link-color: #000000;
  --bs-nav-link-hover-color: #000000;
  --bs-nav-pills-link-active-color: #000000;
  --bs-nav-pills-link-active-bg: #f8de3e;
}